<template>
    <div class="content-wrapper">
        <!-- <div v-if="loader" class="page_loader"></div> -->
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <h1 class="m-0 text-dark">Under Review Modify</h1>
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                    <div class="col-sm-8">
                        
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row booking-page">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="card">
                            <div class="card-body"> 
                                <b-overlay :show="loading">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(saveForm)" v-on:keyup.enter="saveForm" :class=" isFraud ? 'text-red' : 'text-black'">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-9 col-xs-12">
                                                    <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="merchant_id"
                                                                v-model="form.merchant_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.merchantList
                                                                :readonly="1"
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>                                         
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Date" vid="date" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="date">Deadline <span class="text-danger" title="Required">*</span></label>
                                                            <input type="date" id="date" v-model="form.date" class="form-control" placeholder="Deadline" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min:11|max:11">
                                                        <div class="form-group">
                                                            <label for="mobile">Mobile No<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="mobile" v-model="form.mobile" class="form-control" placeholder="Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Alternatvie Mobile" vid="mobile_2" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="mobile_2">Alt. Mobile No</label>
                                                            <input type="text" id="mobile_2" v-model="form.mobile_2" class="form-control" placeholder="Alternatvie Mobile No" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Reference ID" vid="ref_id" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="ref_id">Reference ID</label>
                                                            <input type="text" id="ref_id" v-model="form.ref_id" class="form-control" placeholder="Reference ID" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="district_id">District <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="district_id"
                                                                v-model="form.district_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.districtList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                                                    <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="thana_id">Thana <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="thana_id"
                                                                v-model="form.thana_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= thanaList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                                                    <ValidationProvider name="Area" vid="area_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="area_id">Area <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="area_id"
                                                                v-model="form.area_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= areaList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                                                    <ValidationProvider name="Destination" vid="destination_hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="thana_id">Destination Hub <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="thana_id"
                                                                v-model="form.destination_hub_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.hubList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Price" vid="price" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="price">Price <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="price" v-model="form.price" class="form-control" placeholder="Price" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Customer Name" required />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="address" v-model="form.address" class="form-control" placeholder="Address" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Instruction (Note)" vid="instruction" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="instruction">Instruction (Note)</label>
                                                            <input type="text" id="instruction" v-model="form.instruction" class="form-control" placeholder="Instruction (Note)" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Description" vid="description" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="description">Product Description</label>
                                                            <input type="text" id="description" v-model="form.description" class="form-control" placeholder="Product Description" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Weight(kg)" vid="weight" v-slot="{errors}">
                                                       <div class="form-group">
                                                            <label for="weight">Weight(kg) </label>
                                                            <v-select name="weight"
                                                                v-model="form.weight"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.weightChargeList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Weight Charge" vid="weight_charge" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="weight_charge">Weight Charge <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="weight_charge" v-model.number="form.weight_charge" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>                                                
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Exchange" vid="exchange" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="exchange">Exchange <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="exchange"
                                                                v-model="form.exchange"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= exchangeList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Category" vid="category_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="category_id">Category <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="category_id"
                                                                v-model="form.category_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= customState.categoryList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>  
                                            </div>
                                            <div class="row">
                                                <div class="ccol-xl-10 col-lg-10 col-md-10 col-sm-6 col-xs-12"></div>
                                                <div class="ccol-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 text-right">
                                                    <div class="form-group">
                                                        <button type="button" class="btn btn-info mr-2 mt-40" @click.prevent="saveForm">Submit</button>
                                                        <!-- <button type="reset" class="btn btn-danger btn-sm mt-30">Cancel</button> -->
                                                    </div>
                                                </div>
                                            </div>  
                                            <div class="row mt-1" v-if="fraudResponse">
                                                <div class="col text-center">
                                                    <p>
                                                        <span class="text-danger">Total Cancel: {{ fraud.total_cancel }}</span> | 
                                                        <span class="text-success">Total Delivered: {{ fraud.total_success }} | Success Rate: {{ fraud.success_rate }}%</span>
                                                    </p>
                                                </div>
                                            </div>  
                                        </form>
                                    </ValidationObserver>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-title m-0 text-dark">Under Review Order</h2>
                            </div>
                            <div class="card-header">
                                <form @submit.prevent="searchData">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="date">Date</label>
                                                <input type="date" id="date" v-model="search.date"  placeholder="Mobile no" class="form-control"/>
                                            </div>
                                        </div>                                    
                                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="merchant_id">Merchant</label>
                                                <v-select name="merchant_id"
                                                    v-model="search.merchant_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.merchantList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="mobile">Mobile</label>
                                                <input type="text" id="mobile" v-model="search.mobile"  placeholder="Mobile no" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <p class="mt-40">Total : <b>{{ total }}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body"> 
                                <b-overlay :show="orderLoading">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%">SL</th>
                                                    <th style="width:30%">Customer</th>
                                                    <th style="width:15%">Date</th>
                                                    <th style="width:50%">Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in orders" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td @click="setDataInForm(item)">{{ item.name }}, {{ item.mobile }}</td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.address }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div ref="bulkUploadModal" class="modal fade" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <BulkUpload :bulkModal="bulkModal" :merchantList="commonObj.merchantList"></BulkUpload>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    import { Modal } from 'bootstrap'
    import BulkUpload from './BulkUpload.vue'
    import moment from 'moment'
    import Label from './Label.js'
    export default {
        name:'Booking',
        components: {
            BulkUpload: BulkUpload
        },
        data () {
            return {
                bulkModal: 0,
                loading: false,
                orderLoading: false,
                form: null,
                exchangeList: [
                    { id: 1, text: 'Yes' },
                    { id: 2, text: 'NO' },
                ],
                search: {
                    merchant_id: '',
                    mobile: '',
                    date: moment().format('YYYY-MM-DD')
                },
                districtList: [],
                thanaList: [],
                zoneList: [],
                areaList: [],
                orders: [],
                printOrders: [],
                isFraud: false,
                fraudResponse: false,
                fraud: null,
                total: 0,
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        watch: {
            'form.district_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.thanaList = this.getThanaList(newVal)
                }
            },
            'form.thana_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.areaList = this.getAreaList(newVal)
                }
            },
            'form.area_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.setHub(newVal)
                }
            },
            'form.weight': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.form.weight_charge = this.getWeightCharge(newVal)
                }
            },
            'form.mobile': function (newVal, oldVal) {
                if (newVal != oldVal && newVal.length >= 11) {
                    this.search.mobile = newVal
                    this.underReviewOrder()
                }
            },
            'search.mobile': function (newVal, oldVal) {
                if (newVal != oldVal && newVal.length >= 11) {
                    // this.fraudCheck(newVal)
                    this.search.mobile = newVal
                    this.underReviewOrder()
                }
            },
            'form.merchant_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.search.merchant_id = newVal
                    this.underReviewOrder()
                }
            },
            'search.merchant_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.search.merchant_id = newVal
                    this.underReviewOrder()
                }
            }
        },
        mounted() {
            this.bulkModal = new Modal(this.$refs.bulkUploadModal)
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            customState () {
                return this.$store.state
            },
            commonObj () {
                return this.$store.state.commonObj
            }
        },
        methods:{
            searchData () {
                this.underReviewOrder()
            },
            async underReviewOrder () {
                this.orderLoading = true
                const response = await config.getData(`/order/under-review`, this.search)
                this.orderLoading = false
                if (response.status == 200) {
                    this.total = response.total
                    this.orders = response.data.data
                } else {
                    this.total = 0
                    this.orders = []
                }
            },
            setDataInForm (item) {
                const district = this.commonObj.districtList.find(tmp => tmp.text === item.district)                              
                const tmpItem = Object.assign(item, { district_id: district ? district.id : '', category_id: 1 })
                this.form =  JSON.parse(JSON.stringify(tmpItem))
            },
            getWeightCharge (weight) {
                const tmpCharge = this.commonObj.weightChargeList.find(el => el.id == weight)
                return typeof tmpCharge != 'undefined' ? tmpCharge.charge : 0
            },
            async saveForm () {
                this.loading = true
                const response = await config.postData('under-review/order-store', this.form)
                this.loading = false
                if (response.success) {
                    this.setDefaultForm()
                    this.underReviewOrder()
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })  
                    this.printLabel(response.data)
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                    this.$refs.form.setErrors(response.errors) 
                }
            },
            setDefaultForm () {
                this.form.name = this.form.mobile = this.form.mobile_2 = this.form.thana_id = this.form.district_id = this.form.destination_hub_id = ''
                this.form.zone_id = this.form.area_id = this.form.address = this.form.ref_id = this.form.instruction = this.form.description = this.search.mobile = ''
                this.form.category_id = 1,
                this.form.price = this.form.weight = this.form.weight_charge = 0
                this.form.exchange = 2                    
                this.$nextTick(() => {
                    this.$refs.form.reset()
                })
            },
            getDivisionList (areaCoverageId) {
                this.form.division_id = this.form.thana_id = this.form.district_id = this.form.area_id = ''
                if (areaCoverageId == 1) {
                    this.form.division_id = 3
                    this.getDistrictList(this.form.division_id)
                    return this.commonObj.divisionList.filter(el => el.id == 3)
                }
                return this.commonObj.divisionList
            },
            getThanaList (districtId) {
                return this.commonObj.thanaList.filter(el => el.district_id == districtId)
            },
            getZoneList (thanaId) {
                return this.commonObj.zoneList.filter(el => el.thana_id == thanaId)
            },
            getAreaList (thanaId) {
                return this.commonObj.areaList.filter(el => el.thana_id == thanaId)
            },
            setHub (areaId) {
                const area = this.commonObj.areaList.find(el => el.id == areaId)
                this.form.destination_hub_id = area.hub_id
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            printLabel(item) {
                this.printOrders = []
                this.printOrders.push(item)
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                Label.exportLabelDetails(base64Logo, this.printOrders)
            }
        }
    }
</script>
<style scoped>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    .mt-30 {
        margin-top: 30px !important;
    }
    .mt-40 {
        margin-top: 40px !important;
    }
    .booking-page .form-group label, .booking-page .form-group select option, .booking-page .form-group input::placeholder,  .booking-page .form-group input{
        font-size: 20px;
    }

    /* For Desktop View */
        @media screen and (min-width: 1024px) {
            .gfg-div {
                background-color: #63c971;
                color: #fff;
            }
        }
        
        /* For Tablet View */
        @media screen and (min-device-width: 768px) 
            and (max-device-width: 1024px) {
            .gfg-div {
                width: 400px;
                height: 400px;
                background-color: orange;
                color: black;
            }
        }
        
        /* For Mobile Portrait View */
        @media screen and (max-device-width: 480px) 
            and (orientation: portrait) {
            .gfg-div {
                width: 200px;
                height: 200px;
                background-color: red;
                color: #fff;
            }
        }
        
        /* For Mobile Landscape View */
        @media screen and (max-device-width: 640px) 
            and (orientation: landscape) {
            .gfg-div {
                width: 400px;
                height: 200px;
                background-color: cyan;
                color: black;
            }
        }
        
        /* For Mobile Phones Portrait or Landscape View */
        @media screen
            and (max-device-width: 640px) {
            .gfg-div {
                width: 400px;
                height: 200px;
                background-color: chartreuse;
                color: black;
            }
        }
        
        /* For iPhone 4 Portrait or Landscape View */
        @media screen and (min-device-width: 320px) 
            and (-webkit-min-device-pixel-ratio: 2) {
            .gfg-div {
                width: 400px;
                height: 400px;
                background-color: brown;
                color: black;
            }
        }
        
        /* For iPhone 5 Portrait or Landscape View */
        @media (device-height: 568px) 
            and (device-width: 320px) 
            and (-webkit-min-device-pixel-ratio: 2) {
            .gfg-div {
                width: 400px;
                height: 400px;
                background-color: cornflowerblue;
                color: black;
            }
        }
        
        /* For iPhone 6 and 6 plus Portrait or Landscape View */
        @media (min-device-height: 667px) 
            and (min-device-width: 375px) 
            and (-webkit-min-device-pixel-ratio: 3) {
            .gfg-div {
                width: 400px;
                height: 400px;
                background-color: darkgoldenrod;
                color: black;
            }
        };
</style>